// @mui
import { styled } from '@mui/material/styles';
import { Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(() => ({
  width: '100%',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  //   justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function NoPosts() {
  return (
    <Container>
      <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Box component="img" src="/static/illustrations/nodata.png" sx={{ mx: 'auto', my: { xs: 5, sm: 10 } }} />
        <Typography variant="h6" sx={{ mt: -2 }}>
          No new updates
        </Typography>
        <Typography variant="overline" sx={{ mt: 1 }}>
          Good Job! You are all caught up! 🎉
        </Typography>
      </ContentStyle>
    </Container>
  );
}
