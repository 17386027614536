import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Backdrop, Box, Card, CircularProgress, Stack, Typography } from '@mui/material';
import { format } from 'fecha';
import { getAPIKey, SERVER } from '../../store/common/apiUtils';

import './post.css';
import useResponsive from '../../hooks/useResponsive';
import useOnScreen from '../../hooks/useOnScreen';

export default function Postcard({ _id, title, fileUrl, createdAt }) {
  const [content, setContent] = useState('');
  const [fetching, setFetching] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const postRef = useRef(null);
  const smallDevice = useResponsive('down', 'md');

  // Ref for the element that we want to detect whether on screen
  const ref = useRef(null);
  // Call the hook passing in ref and root margin
  // In this case it would only be considered onScreen if more ...
  // ... than 300px of element is visible.
  const onScreen = useOnScreen(ref, `-${ref.current?.clientHeight ? ref.current.clientHeight - 100 : 100}px`);

  useEffect(() => {
    if (onScreen) {
      axios.post(`${SERVER}/post/${_id}/ticks`, { apiKey: getAPIKey() });
    }
  }, [_id, onScreen]);

  useEffect(() => {
    fetch(fileUrl)
      .then((r) => r.text())
      .then((text) => {
        setFetching(false);
        setContent(text);
        setTimeout(() => {
          const imagesLen = postRef.current.getElementsByTagName('img').length;
          if (postRef.current && imagesLen > 0) {
            for (let i = 0; i < imagesLen; i += 1) {
              postRef.current.getElementsByTagName('img')[i].addEventListener('click', (event) => {
                setImageUrl(event.target.src);
                setShowImage(true);
              });
            }
          }
        }, 200);
      });
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', cursor: 'zoom-out', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showImage}
        onClick={() => setShowImage(false)}
      >
        <div className="post-image-viewer">
          <img src={imageUrl} loading="lazy" alt="" />
        </div>
      </Backdrop>
      <Card sx={{ minHeight: 250, padding: 2, width: smallDevice ? '100%' : 475 }} ref={ref}>
        <div className="post-container" ref={postRef}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 1 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {format(new Date(createdAt), '   MMMM Do, YYYY')}
            </Typography>
          </Stack>
          <h2 className="post-title">{title}</h2>
          {fetching ? (
            <Box sx={{ display: 'flex', mt: 3, justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <div className="post-body" dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      </Card>
    </>
  );
}
