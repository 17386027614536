import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import SuggestionForm from '../sections/sugesstion/suggestionForm';
// import { getOrgName } from '../store/common/apiUtils';


export default function Suggestions() {
  // const orgName = getOrgName();
  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* <Typography
        variant="subtitle1"
        sx={{ flexGrow: 1, color: 'text.primary', textAlign: 'center' }}
      >
        Help {orgName} improve 💯
      </Typography> */}
      {/* <Divider sx={{ my: 2 }} /> */}
      <SuggestionForm />
    </Box>
  );
}
