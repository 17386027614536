import { Box } from '@mui/material';
import React from 'react';
import PostsViewer from '../sections/posts/PostViewer';

export default function Posts() {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <PostsViewer />
    </Box>
  );
}
