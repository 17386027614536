import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from 'axios';
import { getAPIKey, SERVER } from '../common/apiUtils';

const initialState = {
  data: {
    headerColor: '#2065D1',
    textColor: '#FFFFFF',
    showFeatureRequest: false
  },
};

export const fetchOrg = createAsyncThunk('organization/getorg/', async (_, { rejectWithValue, dispatch }) => {
  try {
    const res = await axios.post(`${SERVER}/organization/getorg/`, { apiKey: getAPIKey() });
    if (res?.data?.success) {
      dispatch(setOrgData(res.data?.payload));
      return res?.data?.posts;
    }
    return rejectWithValue(res?.data?.message || 'Something went wrong');
  } catch (err) {
    return rejectWithValue('Something went wrong');
  }
});

const orgSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrgData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {},
});
export const { setOrgData } = orgSlice.actions;

export default orgSlice.reducer;
