import { combineReducers, configureStore } from '@reduxjs/toolkit';
import post from './post/postSlice';
import org from './org/orgSlice';

const appReducer = combineReducers({ post, org });

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === 'LOGOUT') state = undefined;

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
