import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SERVER, getAPIKey } from '../../store/common/apiUtils';
// component

// ----------------------------------------------------------------------

export default function SuggestionForm() {
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');
  const orgData = useSelector((state) => state.org.data);
  const SuggestionFormSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Title required'),
    description: Yup.string().min(50, 'Too Short!').required("Don't forget to share your awesome idea!"),
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email required. This helps us get back to you.'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    validationSchema: SuggestionFormSchema,
    onSubmit: async () => {
      setFormError('');
      setFormSuccess('');
      try {
        const { data } = await axios.post(`${SERVER}/feature/idea`, {
          title: values.title,
          description: values.description,
          email: values.email,
          apiKey: getAPIKey(),
        });
        if (data.success) {
          setFormSuccess('Your idea has been submitted. Thank you!');
        } else {
          setFormError('Something went wrong. Please try again.');
        }
      } catch (err) {
        setFormError('Something went wrong. Please try again.');
      }
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps, setSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Title"
            {...getFieldProps('title')}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title}
          />
          <TextField
            fullWidth
            label="Your awesome idea here"
            multiline
            minRows={4}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          {formError && (
            <Alert variant="filled" severity="error" sx={{ my: 1 }}>
              {formError}
            </Alert>
          )}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ backgroundColor: orgData.headerColor }}
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
          {formSuccess && (
            <Alert variant="filled" severity="success" sx={{ my: 1 }}>
              {formSuccess}
            </Alert>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
}
