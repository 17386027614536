import { Link, Outlet } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Drawer, IconButton, Tab, Tabs, Toolbar, Typography } from '@mui/material';
// components
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../components/Iconify';
import { getOrgName } from '../store/common/apiUtils';
import useResponsive from '../hooks/useResponsive';
import { fetchOrg } from '../store/org/orgSlice';

// ----------------------------------------------------------------------

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const getLabel = (idx, orgName) => {
  const Labels = [`${orgName ? `${orgName}'s ` : ''}Updates`, `Suggestions for ${orgName}`];
  return Labels[idx];
};
export default function LogoOnlyLayout() {
  const dispatch = useDispatch();
  const orgData = useSelector((state) => state.org.data);
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const smallDevice = useResponsive('down', 'md');

  useEffect(() => {
    dispatch(fetchOrg());
    const handleParentMessage = (event) => {
      if (event.data === 'openUpdateBoxDrawer') {
        setOpenDrawer(true);
      }
    };
    window.addEventListener('message', handleParentMessage);

    return () => {
      window.removeEventListener('message', handleParentMessage);
    };
  }, []);

  const handleClose = () => {
    window.parent.postMessage('closeUpdateBoxDrawer', '*');
    setOpenDrawer(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const drawerWidth = smallDevice ? '100%' : '507px';

  return (
    <Drawer
      anchor={'right'}
      open={openDrawer}
      onClose={handleClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: theme.palette.grey[200],
        },
      }}
    >
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: orgData.headerColor,
          color: orgData.textColor,
        }}
      >
        <Toolbar>
          <Typography variant="subtitle1" textTransform={'uppercase'} component="div" sx={{ flexGrow: 1 }}>
            {getLabel(value, orgData.name)}
          </Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </Toolbar>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: orgData.textColor } }}
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab component={Link} to="/" label="UPDATES" {...a11yProps(0)} />
          {orgData.showFeatureRequest && (
            <Tab component={Link} to="/suggestion" label="FEATURE SUGGESTION" {...a11yProps(1)} />
          )}
        </Tabs>
      </AppBar>
      <Box sx={{ padding: 1, paddingTop: 4, display: 'flex', flexGrow: 1, overflow: 'auto', flexDirection: 'column' }}>
        <Outlet />
      </Box>
      <Box sx={{ textAlign: 'center', padding: 1, borderTop: 1, borderColor: '#ececec' }}>
        <Typography variant="body2" sx={{ flexGrow: 1, color: 'text.secondary' }}>
          Powered by{' '}
          <a href="https://updatebox.tech" target={'_blank'} rel="noreferrer">
            UpdateBox
          </a>
        </Typography>
      </Box>
    </Drawer>
  );
}
