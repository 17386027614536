/* eslint-disable no-extra-boolean-cast */
import { Box, Grid, LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ssEvents } from '../../utils/sse';
import { fetchPosts } from '../../store/post/postSlice';
import NoPosts from './NoPosts';
import Postcard from './PostCard';
import { getOrgId } from '../../store/common/apiUtils';

export default function PostsViewer() {
  const { isFetching, posts } = useSelector((state) => state.post);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchPosts());
    const orgId = getOrgId();
    ssEvents.addEventListener(`newPost_${orgId}`, () => {
      dispatch(fetchPosts());
    });
  }, []);

  return (
    <Grid container spacing={3}>
      {isFetching && (
        <Box sx={{ width: '100%', height: '100%' }} alignItems="center" justifyContent={'center'} display="flex">
          <LinearProgress sx={{ width: '100%' }} />
        </Box>
      )}
      {!!posts.length ? (
        posts.map((post) => (
          <Grid item xs={12} key={post._id}>
            <Postcard {...post} />
          </Grid>
        ))
      ) : (
        <>{isFetching ? null : <NoPosts />}</>
      )}
    </Grid>
  );
}
