// routes
import { useEffect } from 'react';
import axios from 'axios';
import Router from './routes';

// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { ssEvents } from './utils/sse';
import { getAPIKey, getOrgId, SERVER } from './store/common/apiUtils';

// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    ssEvents.onopen = (e) => {
      // console.log('open:e :>> ', e);
    };
    ssEvents.onerror = (e) => {
      console.log('error:e :>> ', e);
    };
    ssEvents.onmessage = (e) => {
      // console.log('message:e :>> ', e);
    };
    const orgId = getOrgId();
    ssEvents.addEventListener(`newPost_${orgId}`, () => {
      window.parent.postMessage(
        {
          value: 'newPost',
          count: 1,
        },
        '*'
      );
    });
    let lastUpdated = localStorage.getItem('updatebox_last_updated');
    lastUpdated = lastUpdated === 'undefined'  || lastUpdated === null ? "1970-01-01T00:00:00.000Z" : lastUpdated;
    axios.post(`${SERVER}/post/latest/`, { apiKey: getAPIKey(), lastUpdated }).then((res) => {
      if (res?.data?.success && res.data.payload.count > 0) {
        localStorage.setItem('updatebox_last_updated', res?.data?.payload?.timeStamp);
        window.parent.postMessage(
          {
            value: 'newPost',
            count: res.data.payload.count,
          },
          '*'
        );
      }
    });
    return () => {
      ssEvents.close();
    };
  }, []);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}
